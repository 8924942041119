<template>
  <div class="Start content">

    <div>
      <div class="h2 text-center">
        {{ $t('thanks.text') }}
      </div>
    </div>

    <div class="mt-auto">
      <go :to="`https://deuter-win.ch/?lang=${$i18n.locale}`" class="button mt-6">
        {{ $t('thanks.action') }}
      </go>
    </div>

    <div class="circle" />

  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.commit('resetState');
  },
};
</script>

<style lang="scss" scoped>

img {
  max-width: 20%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

</style>
